import React, { useEffect, useState } from 'react'
import { goToUrl } from '../../../services/links';
import './styles.scss';

interface CounterProps {
    url: string
    reference: string
    lang: string
}

const Counter: React.FC<CounterProps> = ({ reference, url, lang }) => {
    const [timeLeft, setTimeLeft] = useState(21);

    useEffect(() => {
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);

    }, [timeLeft]);

    const getTimmerLabel = () => {
        if (lang === 'es') {
            return 'Por favor espere...'
        }

        return 'Please Wait...'
    }

    const getButtonText = () => {
        if (lang === 'es') {
            return 'Obtener link';
        }

        return 'Get link';
    }

    const goToLink = () => {
        goToUrl(url);
    }

    return (
        <div className='counter-screen'>
            <div className="container mx-auto px-4 text-center h-full w-full">
                <div className="flex flex-col items-center justify-center h-full w-full">
                    {timeLeft > 0 && <p className="text-3xl font-extrabold text-white md:text-4xl">{getTimmerLabel()} <span>{timeLeft}</span></p>}
                    {timeLeft <= 0 && <span onClick={() => goToLink()} className="w-full md:w-auto flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 cursor-pointer">{getButtonText()}</span>}
                </div>
            </div>
        </div>
    )
}


export default Counter