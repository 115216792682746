import React from "react"
import Layout from "../../components/Layout/Layout"
import { graphql } from "gatsby"
import {
  toPlainText,
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../services/helpers'
import Seo from '../../components/SEO/Seo'
import Post from '../../components/Post'

const PostsTemplate = ({ data }) => {
  const { sanityPost } = data
  const {
    slug,
    title,
    mainImage,
    _rawBody,
    _rawExcerpt,
  } = sanityPost

  const seoContent = {
    title: title,
    description: toPlainText(_rawExcerpt),
    image: mainImage.asset.url,
    url: `https://www.ennombrededios.com/${slug.current}`,
    article: true,
  }

  const latestPostsNodes = (data || {}).latestPosts
    ? mapEdgesToNodes(data.latestPosts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  return (
    <Layout>
      <Seo
        content={seoContent}
      />
      <Post
        title={title}
        body={_rawBody}
        mainImage={mainImage.asset.url}
        latestPosts={latestPostsNodes}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PostsQuery($id: String!) {
    sanityPost(slug: { current: { eq: $id } }) {
      slug {
        current
      }
      title
      _rawExcerpt(resolveReferences: {maxDepth: 5})
      _rawBody
      mainImage {
        asset {
          url
        }
        caption
        alt
      }

      categories {
        id
        title
      }
    }

    latestPosts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: $id } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          title

          mainImage {
            asset {
              url
            }
            caption
            alt
          }
        }
      }
    }
  }
`

export default PostsTemplate
