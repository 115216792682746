import React, { useEffect, useState } from "react"
import { canEnter, getNavigatorLanguage, getParameterByName, isValidV4UUID, isBase64, removeQueryStringFromURL, decodeBase64 } from "../../services/links";
import PostDetail from "./PostDetail";
import Counter from "./Counter";
import './styles.scss';

// http://localhost:8000/lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit?ref=c5c6c8d7-7e68-47fc-bc0f-3a0b99208874&u=aHR0cHM6Ly9iaXQubHkvM2pOekpERg%3D%3D

interface PostProps {
    title: string
    mainImage: string
    body: string
    latestPosts: any
}

const Post: React.FC<PostProps> = ({ title, mainImage, body, latestPosts }) => {
    const [loading, setLoading] = useState(true)
    const [viewLink, setViewLink] = useState(false)
    const [reference, setReference] = useState('')
    const [url, setUrl] = useState('')
    const [lang, setLang] = useState('')

    useEffect(() => {
        if (typeof window !== `undefined`) {
            const ref = getParameterByName('ref');
            const u = getParameterByName('u');

            if (!ref || !u || !isValidV4UUID(ref) || !canEnter(ref)) {
                setLoading(false);
                setViewLink(false)
            } else {
                removeQueryStringFromURL()
                setUrl(isBase64(u) ? decodeBase64(u) : u);
                setReference(ref);
                setLang(getNavigatorLanguage());
                setLoading(false)
                setViewLink(true)
            }
        }
    }, []);

    return (
        <>
            {
                viewLink && !loading && <Counter url={url} reference={reference} lang={lang} />
            }
            {
                !viewLink && !loading && <PostDetail title={title} mainImage={mainImage} body={body} latestPosts={latestPosts} />
            }
        </>
    )
}
export default Post
