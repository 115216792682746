const uuidsWhiteList = [
    'c5c6c8d7-7e68-47fc-bc0f-3a0b99208874', // anterux
    'ed3d49b0-cb01-44b1-87de-54acab53f3d2', // anterux latino
];
const uuidV4Regex = /^[A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}$/i;
const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
const lastPostSlug = "el-discernimiento-como-busqueda-del-bien"

const hideReferrerLink = "https://href.li/"

export const isValidV4UUID = uuid => uuidV4Regex.test(uuid);
export const canEnter = (ref) => uuidsWhiteList.includes(ref);
export const encodeBase64 = (str) => btoa(str)
export const decodeBase64 = (str) => atob(str)
export const isBase64 = (str) => base64regex.test(str)

export const getGoButtonLink = (url, ref) => {
    const parseUrl = isBase64(url) ? url : encodeBase64(url);
    return `${hideReferrerLink}?${window.location.origin}/${lastPostSlug}?ref=${ref}&u=${encodeURIComponent(parseUrl)}`
}

export const goToUrl = (url) => {
    if (typeof window !== `undefined`) {
        window.location.href = url
    }
}


export const getNavigatorLanguage = () => {
    if (typeof window !== `undefined`) {
        return navigator.language || window.navigator['userLanguage'];
    }
}

export const removeQueryStringFromURL = () => {
    if (typeof window !== `undefined`) {
        window.history.replaceState(null, null, window.location.pathname)
    }
}
export const goToHome = () => {
    if (typeof window !== `undefined`) {
        window.location.href = '/'
    }
}

export const getParameterByName = (name) => {
    if (typeof window !== `undefined`) {
        const match = RegExp('[?&]' + name + '=([^&]*)').exec(
            window.location.search
        )
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
    } else {
        return null
    }
}