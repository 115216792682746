import { Link } from 'gatsby'
import React from 'react'

interface LatestPostsProps {
    posts: any
}

const LatestPosts: React.FC<LatestPostsProps> = ({ posts }) => {
    return (
        <div className='w-full bg-white rounded-lg shadow-lg flex flex-col mb-4 p-6'>
            <div className='mb-4'>
                <h3 className='text-lg lg:text-xl font-extrabold text-gray-900 mb-2'>
                    Entradas recientes
                </h3>
                <div className='h-1 w-12 bg-primary mb-6 rounded' />
                <div className='flex flex-wrap'>
                    {
                        posts &&
                        posts.map((post, index) => {
                            return (
                                <Link
                                    key={index}
                                    to={`/${post.slug.current}`}
                                    className='flex mb-4 w-full'
                                >
                                    <img
                                        className='w-16 h-16 object-cover flex-shrink-0 mr-3'
                                        srcSet={`${post.mainImage.asset.url}?w=64&h=64&fit=crop&q=80&auto=format, ${post.mainImage.asset.url}?w=128&h=128&fit=crop&q=80&auto=format 2x`}
                                        src={
                                            post.mainImage.asset.url +
                                            '?w=64&h=64&fit=crop&q=80&auto=format'
                                        }
                                        width='64px'
                                        height='64px'
                                        loading='lazy'
                                        alt={post.title}
                                    />
                                    <h4 className='w-full'>{post.title}</h4>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}


export default LatestPosts