import React from 'react'
import { imageUrlFor } from '../../../services/image-url.js';
import Navbar from '../../Navbar';
import LatestPosts from "../LatestPosts";
import PostDecoration from "../PostDecoration";

const BlockContent = require('@sanity/block-content-to-react')
const serializers = {
    types: {
        mainImage: (props) => {
            const { alt, caption, asset } = props.node;
            const imageUrl = imageUrlFor(asset._ref).url();
            return (
                <figure>
                    <img
                        className='w-full rounded-lg'
                        srcSet={`${imageUrl}?w=740&q=75&auto=format, ${imageUrl}?w=1024&q=75&auto=format 2x`}
                        src={`${imageUrl}?w=740&q=75&auto=format` || ''}
                        alt={alt || ''}
                        loading='lazy'
                    />
                    {
                        caption && <figcaption>{caption}</figcaption>
                    }
                </figure>
            )
        },

        block: (props) => {
            const { style = 'normal' } = props.node

            if (style === 'blockquote') {
                return (
                    <blockquote>
                        <p>{props.children}</p>
                    </blockquote>
                )
            }

            return BlockContent.defaultSerializers.types.block(props)
        }
    }
}

interface PostDetailProps {
    title: string
    mainImage: string
    body: string
    latestPosts: any
}

const PostDetail: React.FC<PostDetailProps> = ({ title, mainImage, body, latestPosts }) => {
    return (
        <>
            <Navbar />
            <div className="flex items-center justify-center py-20 md:py-32 bg-cover bg-top"
                style={{
                    backgroundImage: 'url(https://res.cloudinary.com/omochism/image/upload/f_auto,q_75/v1613839563/blog/gxvqutq8dtltwitod8ff.jpg)'
                }}>
                <div className="conteiner text-center">
                    <h1 className="post-title m-0 text-3xl tracking-tight font-extrabold text-white sm:text-4xl md:text-5xl">
                        {title}
                    </h1>
                </div>
            </div>
            <div className='container mx-auto flex flex-wrap py-16'>
                <section className='post relative bg-white overflow-hidden lg:h-full lg:w-2/3 w-full flex flex-col items-center px-4'>
                    <PostDecoration />
                    <article className='relative'>
                        <div className='prose prose-lg mx-auto'>
                            <figure>
                                <picture>
                                    <source srcSet={`${mainImage}?w=800&q=75&auto=format, ${mainImage}?w=1024&q=75&auto=format 2x`}
                                        media="(min-width: 768px)" />
                                    <source srcSet={`${mainImage}?w=500&q=75&auto=format, ${mainImage}?w=800&q=75&auto=format 2x`}
                                        media="(min-width: 480px)" />
                                    <source srcSet={`${mainImage}?w=320&q=75&auto=format, ${mainImage}?w=640&q=75&auto=format 2x`}
                                        media="(min-width: 320px)" />
                                    <img
                                        src={`${mainImage}?w=800&q=75&auto=format`}
                                        className='main-image w-full rounded-lg mt-0'
                                        alt={title}
                                        width='735'
                                        height='400'
                                        loading='lazy' />
                                </picture>
                            </figure>
                        </div>
                        <div className='mt-6 prose prose-lg text-gray-600 mx-auto'>
                            <BlockContent
                                blocks={body}
                                serializers={serializers}
                            />
                        </div>
                    </article>
                </section>
                <aside className='w-full lg:w-1/3 flex flex-col items-center px-3'>
                    {
                        latestPosts && <LatestPosts posts={latestPosts} />
                    }
                </aside>
            </div>
        </>
    )
}


export default PostDetail